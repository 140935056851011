import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import Arrow from '../../../assets/svg-icons/chevron-up.svg'
import { GroupCheckbox } from '../../GroupCheckbox'

export const CollapsedGroups = ({
  groupKey,
  groups,
  handleSelectGroup,
  selectedGroups,
  level = 0,
}) => {
  const currentGroup = groups[groupKey]
  const [isOpen, setIsOpen] = useState(false)

  const hasChildren = currentGroup.child.length > 0
  const marginLeft = `${15 * level}px`

  return (
    <>
      <div style={{ marginLeft }}>
        <GroupCheckbox
          isChecked={selectedGroups.includes(currentGroup.oid)}
          value={currentGroup.oid}
          onChange={e => handleSelectGroup(e, isOpen)}
        >
          <div onClick={() => setIsOpen(!isOpen)}>
            <span className="groupName">{currentGroup.name}</span>
            {hasChildren && (
              <img
                src={Arrow}
                style={{
                  width: '15px',
                  transform: `rotate(${180 * +!isOpen}deg)`,
                }}
              />
            )}
          </div>
        </GroupCheckbox>
      </div>
      {hasChildren && (
        <Collapse isOpen={isOpen}>
          {currentGroup.child.map(groupId => (
            <CollapsedGroups
              groups={groups}
              groupKey={groupId}
              selectedGroups={selectedGroups}
              handleSelectGroup={handleSelectGroup}
              level={hasChildren ? level + 1 : level}
            />
          ))}
        </Collapse>
      )}
    </>
  )
}
